/* Button Component JS File */
$(document).ready(function() {
	const popup = $('.button-ebook-form-trigger');
	popup.on('click', function() {
		const ebookID = $(this).data('ebook-id');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'ebook-popup',
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "ebookPopup",
						ebookID: ebookID
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			}
		});
	});

	/* ==============================================================
		Form Popup Button
	============================================================== */
	$(document).on('click', '.form-popup-trigger', function() {
		const form = $(this).attr('data-form');
		const title = $(this).attr('data-title');
		const content = $(this).attr('data-content');
		if (typeof form !== "undefined") {
			formPopup({
				form: form,
				title: title,
				content: content
			})
		}
	});
});
