import mmenu from 'jquery.mmenu/dist/jquery.mmenu.all';

const socialMedia = [];
if (Object.keys(coreData.socialMedia).length) {
	$.each(coreData.socialMedia, function(key, value) {
		socialMedia.push(value.html);
	});
}

$(document).ready(function() {
	$('#mobile-menu').mmenu({
		extensions: [
			"listview-50",
			"border-full",
			"shadow-page",
			"position-right",
			"pagedim-black"
		],
		navbar: {
			title: "Menu"
		},
		navbars: [
			{
				height: 1,
				content: ['<a href="' + coreData.home + '"><img alt="Site Logo" src="' + coreData.logoSecondary + '"></a>']
			},
			{
				content: ["prev", "title"]
			},
			{
				position: 'bottom',
				content: socialMedia
			}
		]
	}, {
		offCanvas: {
			page: {
				selector: '#page-wrapper'
			}
		}
	});
});

$(document).ready(function() {
	const api = $("#mobile-menu").data("mmenu");
	$("body").addClass('nav-closed');
	if (api) {
		api.bind("opening", function() {
			$("body").addClass('nav-open');
			$("body").removeClass('nav-closed');
		});
		api.bind("closing", function() {
			$("body").removeClass('nav-open');
			$("body").addClass('nav-closed');
		});
	}
});
