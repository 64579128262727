/* Single Product Page JS File */
import owlCarousel from 'owl.carousel';
import select2 from 'select2';
import collapse from 'bootstrap';

let variationImages;

$(document).ready(function() {

	$('body').on('change', '.related-product-select', function() {
		window.location = $(this).val();
	});

	$("#product-specifications-accordion .collapse").collapse()

	// $(".related-product-select").select2();

	variationImages = $(".variations-carousel-owl").owlCarousel({
		items: 2,
		autoplay: false,
		autoHeight: true,
		margin: 20,
		loop: false,
		rewind: false,
		autoplayHoverPause: false,
		mouseDrag: true,
		touchDrag: true,
		dots: true,
		responsive : {
			768: {
				items: 3
			},
			992: {
				items: 4
			},
			1200: {
				items: 5
			}
		}
	});

	function showVariationsByFinish( finish ){
		if ( finish == ''){
			$('#variations-hidden .variation').each(function(){
				const owl   = $(".variations-carousel-owl").data('owl.carousel');
				let elem    = $(this).parent().html();

				$('.variations-carousel-owl').owlCarousel('add', elem).owlCarousel('update').trigger('refresh.owl.carousel');
				$(this).parent().remove();
			});
		} else {
			$('#variations-hidden .variation.'+ finish).each(function(){
				const owl   = $(".variations-carousel-owl").data('owl.carousel');
				let elem    = $(this).parent().html();

				$('.variations-carousel-owl').owlCarousel('add', elem).owlCarousel('update').trigger('refresh.owl.carousel');
				$(this).parent().remove();
			});

			$('.variations-carousel-owl .variation:not(.variation.'+ finish + ')').each(function(){
				const owl   = $(".variations-carousel-owl").data('owl.carousel');
				let targetPos   = $(this).parent().index();
				let elem        = $(this).parent();

				$( elem ).clone().appendTo( $('#variations-hidden') );
				owl.remove(targetPos);
				owl.trigger('refresh.owl.carousel')
			});
		}
	}

});

$.urlParam = function(name){
	let results = new RegExp(name + '=([^&#]*)').exec(window.location.href);
	if (results){
		return results[1];
	}
}

$(document).ready(function() {
	if ($.urlParam('SKU')) {
		let url_sku = window.location.href.match(/(SKU=)(.*?)[^&]+/);
		url_sku = url_sku[0].split("=")[1];
		$.post({
			url: coreData.ajaxurl,
			data: {
				'action': 'getVariationBySKU',
				'sku': url_sku
			},
			success:function(response) {
				response = JSON.parse(response);
				updateVariation( response );
				updateVariationImage( response );
			},
			error: function(errorThrown){
				console.log(errorThrown);
			}
		});
	}
});


$(document).on('click', ".enquire-product", function() {
	const productID = $(this).attr('data-product');
	const variationID = $(this).attr('data-variation');
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'product-enquiry-popup',
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "productEnquiryPopup",
					productID: productID,
					variationID: variationID
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		}
	});
});
