import mhead from 'jquery.mhead';
$(window).on('load', function() {
	const mobileHeader = $('#mobile-header');
	const banner = $("#banner-wrapper");
	mobileHeader.mhead({
		scroll: {
			hide: (banner.height() - mobileHeader.height()) / 1.5,
			show: (banner.height() - mobileHeader.height()) / 1.5,
			tolerance: 2
		}
	});
});
