/**
 *
 * Show the scroll back to top functionality if the user has scrolled past set constant
 *
 * @author Peter Brennan <peter.brennan@surfpacific.com.au>
 * @package surfpacific
 * @version 1.0.0
 * @since 0.0.1
 *
 */

let lastScrollTop = 0;
$(document).ready(function() {
	const amountScrolled = 300;
	$(window).on('scroll', function() {
		if ($(window).scrollTop() > amountScrolled) {
			const st = $(this).scrollTop();
			if (st > lastScrollTop) {
				$('#back-to-top').removeClass('in');
				$(".product-sample-slide-open").addClass('in');
			} else {
				$('#back-to-top').addClass('in');
				$(".product-sample-slide-open").removeClass('in');
			}
			lastScrollTop = st;
		} else {
			$('#back-to-top').removeClass('in');
			$(".product-sample-slide-open").removeClass('in');
		}
	});
	$('#back-to-top').click(function() {
		$('html, body').animate({
			scrollTop: 0
		}, 700);
		return false;
	});
});

/**
 *
 * Fix the footer to the bottom of the page if body
 * height is not greater then the window height
 *
 * @author Peter Brennan <peter.brennan@surfpacific.com.au>
 * @package surfpacific
 * @version 1.0.0
 * @since 0.0.1
 *
 */
const fixFooter = function() {
    if ($(window).height() > $('body').height()) {
        $('#footer-wrapper').addClass('fixed-bottom Fixed');
    } else {
        $('#footer-wrapper').removeClass('fixed-bottom Fixed');
    }
};

$(window).on('load resize', function() {
	fixFooter();
});
