/* Inspiration Feed Block JS File */
let inspirationFeed;
const inspirationFeedCount = $(".inspiration-feed").length;

$(document).ready(function() {
	inspirationFeed = $(".inspiration-feed-owl").owlCarousel({
        items: 7,
        autoplay: true,
        autoHeight: true,
        autoplayTimeout: 7500,
        autoplaySpeed: 7500,
        smartSpeed: 7500,
        loop: true,
        rewind: false,
        autoplayHoverPause: false,
        mouseDrag: false,
        touchDrag: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        margin: 9,
        responsive: {
            0: {
                items: 1,
                loop: (inspirationFeedCount > 1) ? true : false,
                autoplay: (inspirationFeedCount > 1) ? true : false
            },
            768: {
                items: 4,
                loop: (inspirationFeedCount > 4) ? true : false,
                autoplay: (inspirationFeedCount > 4) ? true : false
            },
            1200: {
                items: 7,
                loop: (inspirationFeedCount > 7) ? true : false,
                autoplay: (inspirationFeedCount > 7) ? true : false
            }
        }
    });
});
