$(window).on('load', function() {
	$("#search-popup").detach().appendTo('body');
});

$(document).on('click', ".search-toggle", function() {
	$("body").addClass('search-open');
	$(".search-input").focus();
});

$(document).on('click', ".search-close", function() {
	$("body").removeClass('search-open');
});

$(document).keyup(function(e) {
	if ($("body").hasClass('search-open')) {
		if (e.keyCode === 27) {
			$("body").removeClass('search-open');
		}
	}
});
