import owlCarousel from 'owl.carousel';

$(document).ready(function() {
    const ebookCount = $(".ebook-carousel-wrapper .ebook-item").length;
    if (ebookCount > 1) {
        $(".ebook-carousel-wrapper").show().owlCarousel({
            items: 4,
            autoplay: true,
            autoplayTimeout: 7500,
            autoplaySpeed: 7500,
            smartSpeed: 7500,
            cellPadding: 30,
            loop: ebookCount > 4 ? true : false,
            mouseDrag: false,
            touchDrag: false,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1200: {
                    items: 4
                }
            }
        });
    } else {
        $('.ebook-carousel-wrapper').hide();
    }
});
