import slick from 'slick-carousel';

$(document).ready(function() {
	if ($(".section-product-gallery .slick-product-gallery").length > 0) {
		$('.slick-product-gallery').magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'fade-in',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
			}
		});

		$(".slick-product-gallery").on('init', function() {

		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			infinite: true,
			dots: false,
			fade: true,
			// appendArrows: '.slick-arrows',
			// prevArrow: '<span class="gallery-item-nav"><i class="far fa-chevron-left"></i></span>',
			// nextArrow: '<span class="gallery-item-nav"><i class="far fa-chevron-right"></i></span>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						fade: false,
						slidesToShow: 2
					}
				},
				{
					breakpoint: 991,
					settings: {
						fade: false,
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1199,
					settings: {
						fade: false,
						slidesToShow: 5
					}
				}
			]
		});
	}
});
