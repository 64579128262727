/* Details Bar Component JS File */
import magnificPopup from 'magnific-popup';
window.changeLocation = function() {
	$.magnificPopup.open({
		type: 'ajax',
		items: {},
		mainClass: 'mfp-with-zoom change-location-popup',
		zoom: {
			enabled: true, // By default it's false, so don't forget to enable it
			duration: 300, // duration of the effect, in milliseconds
			easing: 'ease-in-out', // CSS transition easing function
		},
		preload: true,
		closeOnBgClick: false,
		tLoading: 'Loading.. Please Wait.', // remove text from preloader
		ajax: {
			settings: {
				type: "post",
				url: coreData.ajaxurl,
				data: {
					action: "changeLocationPopup"
				},
			},
			cursor: 'mfp-ajax-cur',
			tError: '<a href="%url%">The content</a> could not be loaded.'
		}
	});
};
