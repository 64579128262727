import '../scss/core.scss';

import popover from 'bootstrap';
window.popover = popover;

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import LazyLoad from 'vanilla-lazyload';
window.lazyLoadInstance = new LazyLoad({
	elements_selector: ".lazy"
});

import magnificPopup from 'magnific-popup';
window.magnificPopup = magnificPopup;

$(document).ready(function() {
	lazyLoadInstance.update();
});

$(window).on('load', function() {
	$(".defered-styles").attr('rel', 'stylesheet');
});

import owlCarousel from 'owl.carousel';
window.owlCarousel = owlCarousel;

import slick from 'slick-carousel';
window.slick = slick;

// Resize Sonsor
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
window.ResizeSensor = ResizeSensor;

// Stick Kit
import stick_in_parent from 'sticky-kit/dist/sticky-kit';
window.stick_in_parent = stick_in_parent;

import notify from 'notifyjs-browser';

// Auto import js from component folder
import './../../library/components/acfBlock/acfBlock.js'; import './../../library/components/button/button.js'; import './../../library/components/contentBannerStandard/contentBannerStandard.js'; import './../../library/components/detailsBar/detailsBar.js'; import './../../library/components/detailsBarMobile/detailsBarMobile.js'; import './../../library/components/homeBannerStandard/homeBannerStandard.js'; import './../../library/components/shopByFilter/shopByFilter.js';
// Auto import js from blocks folder
import './../../library/blocks/blogFeed/blogFeed.js'; import './../../library/blocks/featuredInInspiration/featuredInInspiration.js'; import './../../library/blocks/featuredVariations/featuredVariations.js'; import './../../library/blocks/footerMenu/footerMenu.js'; import './../../library/blocks/hoverBoxes/hoverBoxes.js'; import './../../library/blocks/inspiration/inspiration.js'; import './../../library/blocks/inspirationFeed/inspirationFeed.js'; import './../../library/blocks/inspirationHome/inspirationHome.js'; import './../../library/blocks/inspirationInTiles/inspirationInTiles.js'; import './../../library/blocks/introContent/introContent.js'; import './../../library/blocks/keyPoints/keyPoints.js'; import './../../library/blocks/locationBlock/locationBlock.js'; import './../../library/blocks/locationBlockSinlge/locationBlockSinlge.js'; import './../../library/blocks/newsletterSubscription/newsletterSubscription.js'; import './../../library/blocks/pageIntro/pageIntro.js'; import './../../library/blocks/priceGuide/priceGuide.js'; import './../../library/blocks/requestAConsultation/requestAConsultation.js'; import './../../library/blocks/snappptEmbed/snappptEmbed.js';
// Auto import js from widgets folder
import './../../library/widgets/cartTotals/cartTotals.js'; import './../../library/widgets/eBookWidget/eBookWidget.js'; import './../../library/widgets/gravityForm/gravityForm.js'; import './../../library/widgets/navigationMenu/navigationMenu.js'; import './../../library/widgets/orderReview/orderReview.js'; import './../../library/widgets/shopFilter/shopFilter.js';
// Auto import js from templates folder
import './../../library/templates/breadcrumbs/breadcrumbs.js'; import './../../library/templates/client/contactUs/contactAccordion/contactAccordion.js'; import './../../library/templates/client/location/googleMap/googleMap.js'; import './../../library/templates/content/blog/blogPost/blogPost.js'; import './../../library/templates/content/projects/projectGallery/projectGallery.js'; import './../../library/templates/content/search/searchPopup/searchPopup.js'; import './../../library/templates/footer/footer.js'; import './../../library/templates/form/form.js'; import './../../library/templates/guide/guide.js'; import './../../library/templates/header/desktopHeader/desktopHeader.js'; import './../../library/templates/header/header.js'; import './../../library/templates/header/mobileHeader/mobileHeader.js'; import './../../library/templates/mmenu/mmenu.js'; import './../../library/templates/titleSeo/titleSeo.js'; import './../../library/templates/wishlist/addToWishlist/addToWishlist.js'; import './../../library/templates/woocommerce/featuredTiles/featuredTiles.js'; import './../../library/templates/woocommerce/otherTilesInCollection/otherTilesInCollection.js'; import './../../library/templates/woocommerce/productGallery/productGallery.js'; import './../../library/templates/woocommerce/productSampleSlide/productSampleSlide.js'; import './../../library/templates/woocommerce/singleProductGallery/singleProductGallery.js';
// Auto import js from woocommerce folder
import './../../woocommerce/single-product/single-product.js';

import './_woocommerce.js';

import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';
window.PNotify = PNotify;
window.PNotifyButtons = PNotifyButtons;

PNotify.defaults.styling = 'bootstrap4';
PNotify.defaults.icons = 'fontawesome5';

$(document).ready(function() {
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile fade-in',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			titleSrc: function(item) {
				return item.el.attr('data-description').length > 0 ? item.el.attr('data-description') : '';
			}
		}
	});
});

window.matchHeight = function(element) {
	$(window).on('load rotate resize', function() {
		let maxHeight = 0;
		let match;
		match = $(element);
		match.each(function() {
			if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
		});
		match.height(maxHeight);
	});
}

$(document).ready(function() {
	$(".variable-image-popover").popover();
});

// Get the users coordinates
window.getLocation = function(callback) {
	if (coreData.spOptions.location_update !== 'disabled') {
		if ("geolocation" in navigator) {
			function position(position) {
				const userCoords = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				};
				if (typeof callback !== 'undefined') {
					callback(userCoords);
				}
			}
			function error(error) {
				console.log(error);
			}
			const options = {}
			if (coreData.spOptions.location_update == 'change') {
				navigator.geolocation.watchPosition(position, error, options);
			} else if (coreData.spOptions.location_update == 'reload') {
				navigator.geolocation.getCurrentPosition(position, error, options);
			}
		}
	}
};

window.setLocation = function(location, callback) {
	// if the client data has more than 1 location
	if (Object.keys(coreData.clientData).length > 1) {
		// initiate the getLocation function
		getLocation(function(userCoords) {
			const currentLocation = Cookies.get('closest_location');
			$.post(coreData.ajaxurl, {
				action: 'findClosestLocation',
				location: location,
				userCoords: userCoords
			}, function(response) {
				Cookies.set('closest_location', response);
				// No we need to reinitiate all the loadable elements
				response = JSON.parse(response);
				const locationID = response.id;
				let clientData = {};
				if (locationID) {
					clientData = coreData.clientData[locationID];
				}

				// Check to see if the current location is the same
				// as the new location.
				let updateLocation = true;
				if (typeof currentLocation !== "undefined") {
					const location = JSON.parse(currentLocation);
					if (location.id === locationID) {
						updateLocation = false;
					}
				}

				// only update the site if the location has changed
				if (updateLocation) {
					// Only get the templates that have the dynamic template class
					const templates = $(".sp-template[data-load='dynamic']");
					$.each(templates, function(key, template) {
						const templateSlug = $(template).data('template');
						let data = {};
						if ($(template).length) {
							if ($(template).hasClass('merge-tag')) {
								data.is_merge_tag = true;
							}
							$.post(coreData.ajaxurl, {
								action: 'loadTemplate',
								template: templateSlug,
								data: data
							}, function(data) {
								$(template).replaceWith(data);
							});
						}
					});
				}
				if (typeof callback !== 'undefined') {
					callback();
				}
			})
		});
	}
}


// Read a page's GET URL variables and return them as an associative array.
window.getUrlVars = function() {
	let vars = {};
	let hash;
	const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for (let i = 0; i < hashes.length; i++) {
		hash = hashes[i].split('=');
		if (typeof hash[1] !== 'undefined') {
			vars[hash[0]] = hash[1];
		}
	}
	return vars;
}

window.urlGenerator = function(url, key, value) {
    let temp = '(?<='+`${key}`+'=)[^&]+'
    let reg = new RegExp(temp,'g');
    return url.replace(reg, value);
}


$(window).on('load resize', function() {
	if ($(window).width() >= 768) {
		if ($('body').hasClass('woocommerce-page')) return;

		const options = {
			parent: '.sticky-container',
			inner_scrolling: true,
			offset_top: 220
		};

		$(".widgets-wrapper").stick_in_parent(options);
		new ResizeSensor($('.sticky-container'), function() {
			$(".widgets-wrapper").trigger("sticky_kit:recalc");
		});
	}
});

$(document).ready(function(){
    $(this).scrollTop(0);
});

window.formPopup = function formPopup(data) {
	const form = typeof data.form !== "undefined" ? data.form : false;
	const title = typeof data.title !== "undefined" ? data.title : false;
	const content = typeof data.content !== "undefined" ? data.content : false;
	if (form) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'form-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "formPopup",
						form: form,
						title: title,
						content: content
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {

				}
			}
		});
	}
}
