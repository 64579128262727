/* Shop By Filter Component JS File */
$(document).ready(function() {
	if ($(".shop-by-filter-wrapper").length > 0) {

		function scrollToShop() {
			$('html, body').animate({
				scrollTop: $("#content-wrapper").offset().top - 200
			}, 1000);
		}

		const guideSliderSlick = $(".shop-by-filter-wrapper .slick-filter-carousel").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 1,
			focusOnSelect: false,
			autoplay: false,
			autoPlaySpeed: 10000,
			speed: 1000,
			mobileFirst: true,
			adaptiveHeight: true,
			arrows: true,
			dots: false,
			infinite: true,
			centerMode: true,
			centerPadding: 0,
			fade: true,
			rows: 0,
			appendArrows: '.shop-by-filter-arrows',
			prevArrow: '<button class="prev"><i class="fas fa-angle-left"></i></button>',
			nextArrow: '<button class="next"><i class="fas fa-angle-right"></i></button>',
			appendDots: '.shop-by-filter-dots',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						fade: false,
						centerMode: false,
						centerPadding: 0,
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true
					}
				},
				{
					breakpoint: 991,
					settings: {
						fade: false,
						centerMode: true,
						centerPadding: 0,
						slidesToShow: 3,
						slidesToScroll: 1,
						dots: true
					}
				},
				{
					breakpoint: 1199,
					settings: {
						fade: false,
						centerMode: true,
						centerPadding: 0,
						slidesToShow: 5,
						slidesToScroll: 2,
						dots: true
					}
				}
			]
		});

		if ($(".shop-by-gallery").length > 0) {
			$(".shop-by-gallery").owlCarousel({
				items: 1,
				autoplay: false,
				autoHeight: true,
				autoplayTimeout: 1000,
				autoplaySpeed: 1000,
				smartSpeed: 1000,
				loop: true,
				autoplayHoverPause: true,
				mouseDrag: false,
				touchDrag: false,
				dots: true,
				nav: true,
				animateIn: 'fadeIn',
				animateOut: 'fadeOut',
				dotsContainer: '.shop-by-gallery-dots',
				navContainer: '.shop-by-gallery-nav',
			});
		}
	}
});
