import slick from 'slick-carousel';

$(document).ready(function() {

	if ($(".section-single-product-gallery .slick-single-product-gallery").length > 0) {
		$(".slick-single-product-gallery").on('init', function() {

		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 5000,
			speed: 1000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			infinite: true,
			fade: false,
			arrows: true,
			// appendArrows: '.section-single-product-gallery .single-product-gallery-arrows',
			prevArrow: '<span class="gallery-item-nav nav-left"></span>',
			nextArrow: '<span class="gallery-item-nav nav-right"></span>',
			dots: true,
			appendDots: '.section-single-product-gallery .single-product-gallery-navigation',
			customPaging: function(slider, i) {
				return '<button><span>' + ('' + (i + 1)).slice(-2) + '</span></button>';
			},
			responsive: [
				{
					breakpoint: 767,
					settings: {
						fade: false,
						slidesToShow: 1,
					}
				},
			// 	{
			// 		breakpoint: 991,
			// 		settings: {
			// 			fade: false,
			// 			slidesToShow: 3
			// 		}
			// 	},
			// 	{
			// 		breakpoint: 1199,
			// 		settings: {
			// 			fade: false,
			// 			slidesToShow: 5
			// 		}
			// 	}
			]
		});
	}
});
