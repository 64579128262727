$(document).ready(function() {

	if ($(".slick-featured-tiles-carousel").length > 0) {

		setTimeout(function() {
			$.each([
				'.slick-featured-tiles-carousel .collection-product .product-wrapper .section-image',
				'.slick-featured-tiles-carousel .collection-product .product-wrapper .section-title',
				'.slick-featured-tiles-carousel .collection-product .featured-product-wrapper .section-meta',
				'.slick-featured-tiles-carousel .collection-product .product-wrapper .section-price'
			], function(key, element) {
				if ($(element).length) {
					let maxHeight = 0;
					let match;
					match = $(element);
					match.each(function() {
						if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
					});
					match.height(maxHeight);
				}
			})
		}, 200);

		$(".slick-featured-tiles-carousel").on('init', function() {
		}).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 5000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			infinite: true,
			dots: false,
			fade: true,
			// appendArrows: '.slick-arrows',
			// prevArrow: '<span class="gallery-item-nav"><i class="far fa-chevron-left"></i></span>',
			// nextArrow: '<span class="gallery-item-nav"><i class="far fa-chevron-right"></i></span>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						fade: false,
						slidesToShow: 2
					}
				},
				{
					breakpoint: 991,
					settings: {
						fade: false,
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1199,
					settings: {
						fade: false,
						slidesToShow: 6
					}
				}
			]
		});
	}

})
