/* Content Banner Standard Component JS File */
import Rellax from 'rellax';
import owlCarousel from 'owl.carousel';

let contentBanner;

$(document).ready(function() {
	contentBanner = $(".content-banner-owl").owlCarousel({
        items: 1,
        autoplay: true,
        autoHeight: true,
        autoplayTimeout: 7500,
        autoplaySpeed: 7500,
        smartSpeed: 7500,
        loop: false,
        rewind: true,
        autoplayHoverPause: false,
        mouseDrag: false,
        touchDrag: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut'
    });

	// if ($(".content-banner-owl .slide-background").length) {
 //        if ($(window).width() <= 767) {
 //            const contentBannerImageParallax = new Rellax('.content-banner-owl .slide-background', {
 //                speed: -3
 //            });
 //        } else if ($(window).width() >= 768) {
 //            const contentBannerImageParallax = new Rellax('.content-banner-owl .slide-background', {
 //                speed: -10
 //            });
 //        }
 //    }
});
