/* Details Bar Mobile Component JS File */

// close the details bar if pressed outside of the menu
window.closeMenuBarOnDocument = function(e) {
	if ($('body').hasClass('details-bar-menu-open')) {
		if (!$(e.target).parents(".nav-item").length && !$(e.target).parents(".details-bar-menu").length) {
			$('body').removeClass('details-bar-menu-open');
			$(".details-bar-menu").removeClass('menu-open');
		}
	}
};

window.closeMenuBar = function() {
	$('body').removeClass('details-bar-menu-open');
	$(".details-bar-menu").removeClass('menu-open');
};

$(document).on('click', function(e) {
	closeMenuBarOnDocument(e);
});

// close the location select bar if pressed outside of the menu
window.closelocationSelectBarOnDocument = function(e) {
	if ($('body').hasClass('details-bar-location-menu-open')) {
		if (!$(e.target).parents(".details-bar-menu-location-select").length && !$(e.target).parents(".details-bar-menu").length && !$(e.target).parents(".change-location-trigger-mobile").length) {
			$('body').removeClass('details-bar-location-menu-open');
			$(".details-bar-menu-location-select").removeClass('menu-open');
		}
	}
};

// open the mobile locations drop down
$(window).on('load', function() {
	$(".trigger-location-dropdown").on('click', function() {
		const location = $(this).attr('data-location');
		if ($(this).hasClass('location-active')) {
			$(".details-bar-menu").removeClass('menu-open');
			$(this).removeClass('location-active');
			$('body').removeClass('details-bar-menu-open');
		} else {
			$(".details-bar-menu").removeClass('menu-open');
			$(".trigger-location-dropdown").removeClass('location-active');
			$(this).addClass('location-active');
			$('body').addClass('details-bar-menu-open');
			$(".details-bar-menu-" + location).addClass('menu-open');
		}
	});
});
