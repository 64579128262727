/* eBook Widget JS File */
$(document).ready(function() {
	const popup = $('.widget-ebook-form-trigger');
	popup.on('click', function() {
		const ebookID = $(this).data('ebook-id');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'ebook-popup',
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "ebookPopup",
						ebookID: ebookID
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			}
		});
	});
});
