/* Shop Filter JS File */
import collapse from 'bootstrap';
import pjax from 'pjax';

$(document).ready(function() {

	window.ajaxAction = function ajaxAction() {

		let $form = $("#shop-filter-form");

		if (typeof ($.fn.pjax) == 'undefined') {
			return;
		}
		let formDataArray = [];
		let queryFilters = [];
		formDataArray = $("#shop-filter-form :input[value!='']").serializeArray();

		$.each(formDataArray, function(key, filter) {
			const filterName = filter.name;
			const filterValue = filter.value;
			const searchParamsLength = filterValue.split(',').length;
			if (searchParamsLength > 1) {
				const queryTypeFilter = filterName.replace("filter_", "query_type_");
				formDataArray.push({
					name: queryTypeFilter,
					value: 'or'
				});
			}
		});

		const formData = $.param(formDataArray);

		let uri = window.location.toString();
		uri = uri.split('page/')[0]; // remove pagination from url
		let url;
		if (uri.indexOf("?") > 0) {
			const clean_uri = uri.substring(0, uri.indexOf("?"));
			url = clean_uri + "?" + formData;
		} else {
			url = uri + "?" + formData;
		}

		$.pjax({
			container: '.site-main',
			timeout: 0,
			url: url,
			scrollTo: false
		});
	}

	$(document).on('click', '.clear-filter', function(e) {
		e.preventDefault();
		const url = $(this).attr('data-shop');
		$.pjax({
			container: '.site-main',
			timeout: 0,
			url: url,
			scrollTo: false
		});
		$(".form-check-input").prop("checked", false);
		$(".filter-all").click();
	});

	$(document).on('change', '#product-filter-accordion .form-check-input', function(e) {
		e.preventDefault();
		const filterName = $(this).attr('data-filter');
		const removeFilter = $(this).attr('data-remove');
		if (typeof removeFilter !== "undefined") {
			$("." + removeFilter).prop('checked', false);
		} else {
			const filterClass = filterName.replace("_", "-");
			$("[data-remove='" + filterClass + "']").prop('checked', false);
		}

		const filterCheckboxes = $('#product-filter-accordion input[data-filter=' + filterName + ']');
		const filterInput = $('#product-filter-accordion input[name=' + filterName + ']');

		let checkedFilterArray = [];
		$.each(filterCheckboxes, function() {
			if ($(this).is(':checked') && $(this).val() !== "any") {
				checkedFilterArray.push($(this).val());
			}
		});

		const checkedFilters = checkedFilterArray.join(',');
		filterInput.val(checkedFilters);

		ajaxAction();
	});
});
