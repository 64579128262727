/* Hover Boxes Block JS File */
$(document).ready(function() {
	if ($(".hover-boxes-wrapper .slick-hover-boxes").length) {

		const hoverBoxesSlick = $(".hover-boxes-wrapper .slick-hover-boxes").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 1,
			focusOnSelect: false,
			autoplay: false,
			autoPlaySpeed: 10000,
			speed: 1000,
			mobileFirst: true,
			adaptiveHeight: true,
			arrows: true,
			dots: false,
			infinite: true,
			centerMode: true,
			centerPadding: 0,
			fade: true,
			rows: 0,
			appendArrows: '.shop-by-filter-arrows',
			prevArrow: '<button class="prev"><i class="fas fa-angle-left"></i></button>',
			nextArrow: '<button class="next"><i class="fas fa-angle-right"></i></button>',
			appendDots: '.shop-by-filter-dots',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						fade: false,
						centerMode: false,
						centerPadding: 0,
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true
					}
				},
				{
					breakpoint: 991,
					settings: {
						fade: false,
						centerMode: true,
						centerPadding: 0,
						slidesToShow: 3,
						slidesToScroll: 1,
						dots: true
					}
				},
				{
					breakpoint: 1199,
					settings: {
						fade: false,
						centerMode: true,
						centerPadding: 0,
						slidesToShow: 5,
						slidesToScroll: 2,
						dots: true
					}
				}
			]
		});
	}
});
