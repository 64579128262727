$(".toggle-wishlist-trigger:not(.trigger-disabled)").on("click", function(e) {
	const $this = $(this);
	const productID = $(this).data('product');
	const wishlistID = $(this).data('wishlist');
	const type = $(this).data('type');

	if ($this.hasClass('wishlist-active')) {
		const $next = $this.siblings('.wishlist-disabled');
		$this.removeClass('d-block').addClass('d-none');
		$next.removeClass('d-none').addClass('d-block');
	} else {
		const $next = $this.siblings('.wishlist-active');
		$this.removeClass('d-block').addClass('d-none');
		$next.removeClass('d-none').addClass('d-block');
	}
	e.preventDefault();
	$.post(coreData.ajaxurl, {
		action: 'wishlistAddRemoveProduct',
		productID: productID,
		wishlistID: wishlistID,
		type: type
	});
});
