/* Blog Feed Block JS File */
$(window).on('resize',function() {
	if ($(".blog-feed-block").length) {
		if ($(window).width() <= 768) {
			matchHeight('.blog-feed-block .section-blog .section-body');
		} else {
			$('.blog-feed-block .section-blog .section-body').css('height', 'auto');
		}
	}
});
$(document).ready(function() {
    $(window).trigger('resize');
});
