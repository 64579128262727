$(document).on('click', '.product-sample-slide.active .close-popup', function() {
	$(".product-sample-slide").removeClass('active');
});

$(document).on('click', '.product-sample-slide-open .open-popup', function() {
	$(".product-sample-slide").addClass('active');
});

// Ajax delete product in the cart
$(document).on('click', "a.remove-sample", function(e) {

	e.preventDefault();

	const $this = $(this);

	if ($(".product-sample-slide").length > 0) {

		const productSampleRow = $(".product-sample-slide .product-samples .sample-row");
		const sampleColumn = $(".product-sample-slide .product-samples .sample-column");
		const noSampleColumn = $(".product-sample-slide .product-samples .no-sample-column");
		const hasSampleColumn = $(".product-sample-slide .product-samples .has-sample-column");
		const clickedProductSampleColumn = $this.closest('.product-sample-slide .product-sample.has-sample').parent();
		const firstHiddenSampleColumn = $(".product-sample-slide .product-samples .sample-column.d-none").first();

		clickedProductSampleColumn.remove();
		firstHiddenSampleColumn.removeClass('d-none');

		const product_id = $(this).attr("data-product_id");
		const cart_item_key = $(this).attr("data-cart_item_key");

		$.ajax({
			type: 'POST',
			dataType: 'json',
			url: coreData.ajaxurl,
			data: {
				action: "product_remove",
				product_id: product_id,
				cart_item_key: cart_item_key
			},
			success: function(response) {

				if ( ! response || response.error )
					return;

				const fragments = response.fragments;

				const sample_count = response.sample_count;
				if (sample_count <= 5) {
					$(".product-sample-slide .sample-plus").fadeOut();
				}
				// Replace fragments
				if ( fragments ) {
					$.each( fragments, function( key, value ) {
						$(key).html(value);
					});
				}
			}
		});
	}
});
