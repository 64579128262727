$(document).ready(function() {

	const $window = $(window);
	const banner = $("#banner-wrapper");
	const desktopHeader = $('#desktop-header');
	const mobileHeader = $('#mobile-header');
	const body = $("body");
	let isShrunk = false;
	let isMobileShrunk = false;
	$window.on('load scroll', function() {

		const scrolled = $(this).scrollTop();
		let adminBarOffset = 0;

		if (body.hasClass('admin-bar')) {
			if ($(window).width() < 768) {
				adminBarOffset = 44;
			} else {
				adminBarOffset = 32;
			}
		}

		$('.mh-sticky').filter(function() {
			const $this = this;
			if (scrolled >= $($this).offset().top - adminBarOffset) {
				$($this).addClass('mh-stuck');
			} else {
				$($this).removeClass('mh-stuck');
			}
			return '';
		});

		if ($("body").hasClass('no-banner')) {
			desktopHeader.removeClass('outline-nav').addClass('solid-nav')
			mobileHeader.removeClass('outline-nav').addClass('solid-nav');
		} else {
			if ($("#banner-wrapper")[0].getBoundingClientRect().bottom < 0) {
				if (isShrunk === false) {
					isShrunk = true;
					desktopHeader.removeClass('outline-nav').addClass('solid-nav');
				}
				if (isMobileShrunk === false) {
					isMobileShrunk = true;
					mobileHeader.removeClass('outline-nav').addClass('solid-nav');
				}
			} else {
				isShrunk = false;
				desktopHeader.removeClass('solid-nav').addClass('outline-nav');
				isMobileShrunk = false;
				mobileHeader.removeClass('solid-nav').addClass('outline-nav');
			}
		}
	});
});
